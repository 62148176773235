
import Header from "@/components/home-page/Header.vue";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Home",
  components: { Header },
  methods: {
    showChangelog() {
      PopoutsModule.ShowPopout({
        id: "changelog-popout",
        component: "ChangelogPopout",
        data: {},
      });
    },
  },
});
